const Footer = () => (
    <>
        <a target="_blank" rel="noreferrer"
           href="https://docs.google.com/spreadsheets/d/1vnZMw6G_6824obecqMacp6MYyH-L2Fd1mNSMt3N0Whk/edit?usp=sharing">History</a>
        <span className="footer-elements">|</span>
        <a target="_blank" rel="noreferrer" href="https://bitbucket.org/nov0/mob-order/src/master">GIT</a>
        <span className="footer-elements">|</span>
        <span>nov0</span>
    </>
)

export default Footer;
