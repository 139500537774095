import { useState } from "react";
import { saveMobOrder } from "../api/googleSheetApi";
import { generateMobOrder } from "../util/orderGenerator";
import { isToday } from "../util/dateTimeUtil";

const MobOrderButton = ({ participants, lastMobOrder, setLastMobOrder }) => {
    const [isGenerating, setGenerating] = useState(false);

    const handleButtonClick = async () => {
        setGenerating(true);
        const mobOrder = generateMobOrder(participants)
        const order = await saveMobOrder(mobOrder);
        setLastMobOrder(order);
        setGenerating(false);
    }

    const disabled = isGenerating || lastMobOrder === null || lastMobOrder.date == null || isToday(lastMobOrder.date);

    return (
        <button type="button"
            disabled={disabled}
            onClick={handleButtonClick}>
            {isGenerating ? "Generating..." : "Generate"}
        </button>
    )
}

export default MobOrderButton;
