import { useState } from "react";
import { formatOrder } from "../util/orderFormatter";

const CopyButton = ({ lastMobOrder }) => {
    const [isCopied, setCopied] = useState(false);

    const hangleCopy = () => {
        let clipboardText = `Order for today (${lastMobOrder.date}):\n`;
        formatOrder(lastMobOrder).forEach(({ number, ordinal, name }) => {
            clipboardText += `${number}${ordinal}: ${name}\n`
        });
        navigator.clipboard.writeText(clipboardText);
        setCopied(true)
    }


    const disabled = isCopied || lastMobOrder == null || lastMobOrder.date == null;

    return (<button type="button" disabled={disabled} onClick={hangleCopy}>{isCopied ? "Copied" : "Copy"}</button>)
}

export default CopyButton;
