import {GoogleSpreadsheet} from "google-spreadsheet";

const GOOGLE_CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const GOOGLE_PRIVATE_KEY = atob(process.env.REACT_APP_GOOGLE_PRIVATE_KEY);
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;

const ORDER_SHEET_INDEX = 0;
const LAST_ORDER_SHEET_INDEX = 1;
const PARTICIPANTS_SHEET_INDEX = 2;

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export const fetchMobParticipants = async () => {
    const participantsSheet = await loadParticipantsSheet();
    const rows = await participantsSheet.getRows();
    return rows.map(row => row["participants"]);
}

export const fetchLastMobOrder = async () => {
    const lastMobOrderSheet = await loadLastMobOrderSheet();
    const rows = await lastMobOrderSheet.getRows();
    const {date, order} = rows[0] || {};
    return {date, order};
}

export const saveMobOrder = async (names) => {
    const date = new Date().toDateString();
    const order = names.join(", ");

    await saveNewOrder(date, order);
    await saveLastMobOrder(date, order);

    return {date, order}
}

const saveNewOrder = async (date, order) => {
    const mobOrderSheet = await loadMobOrderSheet();
    await mobOrderSheet.addRow({
        date,
        order
    });
}

const saveLastMobOrder = async (date, order) => {
    const lastMobOrderSheet = await loadLastMobOrderSheet();
    const lastMobOrderRows = await lastMobOrderSheet.getRows();
    lastMobOrderRows[0].date = date;
    lastMobOrderRows[0].order = order;
    await lastMobOrderRows[0].save();
}

const loadMobOrderSheet = async () => {
    await doc.loadInfo();
    return doc.sheetsByIndex[ORDER_SHEET_INDEX];
}

const loadLastMobOrderSheet = async () => {
    await doc.loadInfo();
    return doc.sheetsByIndex[LAST_ORDER_SHEET_INDEX];
}

const loadParticipantsSheet = async () => {
    await doc.loadInfo();
    return doc.sheetsByIndex[PARTICIPANTS_SHEET_INDEX];
}

(async function () {
    await doc.useServiceAccountAuth({
        client_email: GOOGLE_CLIENT_EMAIL,
        private_key: GOOGLE_PRIVATE_KEY,
    });
}());
