import {useEffect, useState} from "react";
import {fetchLastMobOrder, fetchMobParticipants} from "../api/googleSheetApi";
import MobOrderButton from "./MobOrderButton";
import MobOrder from "./MobOrder";
import Footer from "./Footer";
import CopyButton from "./CopyButton";

const App = () => {
    const [participants, setParticipants] = useState([]);
    const [lastMobOrder, setLastMobOrder] = useState({});

    useEffect(() => {
        fetchMobParticipants().then(setParticipants)
        fetchLastMobOrder().then(setLastMobOrder)
    }, []);

    return (
        <>
            <h3 className="center">MOB ORDER GENERATOR TOOL</h3>
            <div className="container">
                <span
                    className="order-result-title">List of participants:</span> {participants.map(participant => `${participant}, `)}
            </div>
            <MobOrder lastMobOrder={lastMobOrder}/>
            <div className="container">
                <CopyButton lastMobOrder={lastMobOrder}/>
                <MobOrderButton
                    participants={participants}
                    lastMobOrder={lastMobOrder}
                    setLastMobOrder={setLastMobOrder}/>
            </div>
            <Footer/>
        </>
    );
}

export default App;
