export const formatOrder = (lastMobOrder) => {
    const participants = lastMobOrder.order.split(",");
    return participants.map((name, index) => ({
        name: name && name.trim(),
        number: index + 1,
        ordinal: ordinalSuffix(index),
    }))
}

const ordinalSuffix = (index) => {
    switch (index + 1) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
