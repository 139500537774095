import { formatOrder } from "../util/orderFormatter";
import { isToday } from "../util/dateTimeUtil";

const MobOrder = ({ lastMobOrder }) => {
    if (Object.keys(lastMobOrder).length === 0) return null;

    const isMobOrderGeneratedForToday = isToday(lastMobOrder.date);

    return (
        <div className="container">
            {!isMobOrderGeneratedForToday &&
                <div className="old-result-title">Please generate new mob order for today!</div>
            }
            <div>
                <span className="order-result-title">Date generated:</span>
                <span className={`${!isMobOrderGeneratedForToday ? "old-result" : ""}`}> {lastMobOrder.date}</span>
            </div>
            <div className="order-result-title">Order of participants:</div>
            {lastMobOrder.order && formatOrder(lastMobOrder).map(({ number, ordinal, name }) =>
                <div className={`order-names ${!isMobOrderGeneratedForToday ? "old-result" : ""}`}>
                    <span className="ordinal">{`${number}${ordinal}:`}</span><span>{`${name}`}</span>
                </div>)}
        </div>)
}

export default MobOrder;
