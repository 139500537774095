export const generateMobOrder = (participants) => {
    let order = [];

    while (participants.length > 0) {
        const randomIndex = random(participants.length - 1);
        const selectedParticipant = participants[randomIndex];
        order.push(selectedParticipant);
        participants = participants.filter(participant => participant !== selectedParticipant);
    }

    return order;
}

const random = (max) => Math.round(Math.random() * max);
